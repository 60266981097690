import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VMenu } from 'vuetify/lib';
var InnerMenu = {
  extends: VMenu,
  computed: {
    isTopOverflow: function isTopOverflow() {
      return this.top && this.overflowY < 0;
    },
    isRightOverflow: function isRightOverflow() {
      return this.right && this.overflowX > 0;
    },
    isBottomOverflow: function isBottomOverflow() {
      return this.bottom && this.overflowY > 0;
    },
    isLeftOverflow: function isLeftOverflow() {
      return this.left && this.overflowX < 0;
    },
    overflowX: function overflowX() {
      return this.x - this.calcXOverflow(this.x, this.dimensions.content.width);
    },
    x: function x() {
      var _this$dimensions = this.dimensions,
        activator = _this$dimensions.activator,
        content = _this$dimensions.content;
      var unknown = !this.bottom && !this.left && !this.top && !this.right;
      var activatorLeft = this.attach !== false ? activator.offsetLeft : activator.left;
      var left = 0;
      if (this.top || this.bottom || unknown) {
        left = activatorLeft + activator.width / 2 - content.width / 2;
      } else if (this.left || this.right) {
        left = activatorLeft + (this.right ? activator.width : -content.width) + (this.right ? 10 : -10);
      }
      if (this.nudgeLeft) left -= parseInt(this.nudgeLeft);
      if (this.nudgeRight) left += parseInt(this.nudgeRight);
      return left;
    },
    overflowY: function overflowY() {
      return this.y - this.calcYOverflow(this.y);
    },
    y: function y() {
      var _this$dimensions2 = this.dimensions,
        activator = _this$dimensions2.activator,
        content = _this$dimensions2.content;
      var activatorTop = this.attach !== false ? activator.offsetTop : activator.top;
      var top = 0;
      if (this.top || this.bottom) {
        top = activatorTop + (this.bottom ? activator.height : -content.height) + (this.bottom ? 10 : -10);
      } else if (this.left || this.right) {
        top = activatorTop + activator.height / 2 - content.height / 2;
      }
      if (this.nudgeTop) top -= parseInt(this.nudgeTop);
      if (this.nudgeBottom) top += parseInt(this.nudgeBottom);
      if (this.attach === false) top += this.pageYOffset;
      return top;
    }
  },
  methods: {
    genTransition: function genTransition() {
      var _this = this;
      var content = this.genContent();
      return this.$createElement('transition', {
        props: {
          name: this.transition
        },
        on: {
          'after-leave': function afterLeave() {
            _this.$emit('close');
          }
        }
      }, [content]);
    }
  }
};
export default {
  components: {
    InnerMenu: InnerMenu
  },
  props: VMenu.options.props,
  data: function data() {
    return {
      attributes: _objectSpread(_objectSpread({}, this.$attrs), this.$options.propsData),
      positions: {
        top: this.top,
        left: this.left,
        bottom: this.bottom,
        right: this.right
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$watch(function () {
      return _this2.$refs.menu.dimensions;
    }, function (val) {
      if (val) {
        var directions = [0, 2, 1, 3];
        if (_this2.right) {
          directions = [1, 3, 0, 2];
        } else if (_this2.bottom) {
          directions = [2, 0, 1, 3];
        } else if (_this2.left) {
          directions = [3, 1, 0, 2];
        }
        _this2.updateAttributes(directions);
      }
    });
    this.$watch(function () {
      return _this2.$attrs;
    }, function (val) {
      _this2.attributes = _objectSpread(_objectSpread(_objectSpread({}, val), _this2.$options.propsData), _this2.positions);
    });
    this.$watch(function () {
      return _this2.$options.propsData;
    }, function (val) {
      _this2.attributes = _objectSpread(_objectSpread(_objectSpread({}, _this2.$attrs), val), _this2.positions);
    });
  },
  methods: {
    onClose: function onClose() {
      this.positions = {
        top: this.top,
        left: this.left,
        bottom: this.bottom,
        right: this.right
      };
    },
    isOverflow: function isOverflow(direction) {
      switch (direction) {
        case 0:
          return this.$refs.menu.isTopOverflow;
        case 1:
          return this.$refs.menu.isRightOverflow;
        case 2:
          return this.$refs.menu.isBottomOverflow;
        default:
          return this.$refs.menu.isLeftOverflow;
      }
    },
    updateAttributes: function updateAttributes(directions) {
      var _this3 = this;
      var direction = directions.shift();
      if (this.isOverflow(direction) && this.$refs.menu.isActive) {
        var next = directions[0];
        this.positions = {
          top: next === 0,
          right: next === 1,
          bottom: next === 2,
          left: next === 3
        };
        this.attributes = _objectSpread(_objectSpread({}, this.attributes), this.positions);
        this.$nextTick(function () {
          _this3.updateAttributes(directions);
        });
      }
    }
  }
};