import { VCard } from 'vuetify/lib/components/VCard';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('FloatingMenu', _vm._b({
    attrs: {
      "right": "",
      "position-x": _setup.x,
      "position-y": _setup.y,
      "absolute": !_vm.attach && !_vm.$scopedSlots.activator && !_setup.props.activator,
      "attach": _vm.attach,
      "rounded": _vm.rounded,
      "close-on-content-click": _vm.closeOnContentClick,
      "open-on-hover": _vm.openOnHover,
      "open-delay": _vm.openDelay,
      "close-delay": _vm.closeDelay,
      "disabled": !_setup.menus.length,
      "activator": _vm.activator
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm._t("activator", null, null, {
          on: on,
          attrs: attrs
        })];
      }
    }], null, true),
    model: {
      value: _setup.visible,
      callback: function callback($$v) {
        _setup.visible = $$v;
      },
      expression: "visible"
    }
  }, 'FloatingMenu', {
    'offset-y': !_vm.right,
    'offset-x': _vm.right
  }, false), [_vm._v(" "), _c(VCard, {
    class: {
      'pa-3': !_vm.dense,
      'py-1 px-2': _vm.dense
    },
    attrs: {
      "rounded": _vm.rounded,
      "min-width": "150"
    }
  }, [_vm._l(_setup.menus, function (item, i) {
    var _item$iconColor;
    return [_c('ContextMenuItem', _vm._b({
      key: "menu-item-".concat(_setup.uniqueKey, "-").concat(i),
      attrs: {
        "icon-index": _setup.hasIcon,
        "icon-color": (_item$iconColor = item.iconColor) !== null && _item$iconColor !== void 0 ? _item$iconColor : _vm.iconColor,
        "menus": item.menus,
        "close-on-content-click": _vm.closeOnContentClick
      },
      on: {
        "click": function click($event) {
          return _setup.onMenuClick($event, item);
        },
        "click:submenu": function clickSubmenu($event) {
          _setup.visible = false;
        }
      },
      scopedSlots: _vm._u([_vm.$slots["append.".concat(item.id)] ? {
        key: "append",
        fn: function fn() {
          return [_vm._t("append.".concat(item.id))];
        },
        proxy: true
      } : null, _vm.$slots["tooltip.".concat(item.id)] ? {
        key: "tooltip",
        fn: function fn() {
          return [_vm._t("tooltip.".concat(item.id))];
        },
        proxy: true
      } : null, _vm.$slots["help.".concat(item.id)] ? {
        key: "help",
        fn: function fn() {
          return [_vm._t("help.".concat(item.id))];
        },
        proxy: true
      } : null], null, true)
    }, 'ContextMenuItem', _objectSpread(_objectSpread({}, item), {}, {
      onClick: undefined
    }), false))];
  }), _vm._v(" "), _vm._t("footer")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };