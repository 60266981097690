import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _setup$props$menus, _setup$props$menus2;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "context-menu-item"
  }, [_c('ContextMenuItemButton', {
    attrs: {
      "id": "btn-".concat(_setup.uniqueKey),
      "label": _setup.props.label,
      "icon": _setup.props.icon,
      "icon-index": _setup.props.iconIndex,
      "icon-color": _setup.props.iconColor,
      "disabled": _setup.props.disabled,
      "append-icon": !!((_setup$props$menus = _setup.props.menus) !== null && _setup$props$menus !== void 0 && _setup$props$menus.length) ? 'mdi-chevron-right' : undefined
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click', $event);
      }
    }
  }, [_vm.$slots.append ? _vm._t("append") : _vm._e()], 2), _vm._v(" "), !!((_setup$props$menus2 = _setup.props.menus) !== null && _setup$props$menus2 !== void 0 && _setup$props$menus2.length) ? _c('ContextMenu', {
    attrs: {
      "activator": "#btn-".concat(_setup.uniqueKey),
      "items": _setup.props.menus,
      "close-on-content-click": _setup.props.closeOnContentClick,
      "icon-color": _setup.props.iconColor,
      "open-on-hover": "",
      "right": "",
      "dense": "",
      "open-delay": 100,
      "close-delay": 100
    },
    on: {
      "click": function click($event) {
        return _setup.emits('click:submenu', $event);
      }
    }
  }) : _vm._e(), _vm._v(" "), !!_vm.$slots.tooltip || !!_setup.props.tooltip ? _c('FloatingTooltip', {
    attrs: {
      "activator": "#btn-".concat(_setup.uniqueKey),
      "right": "",
      "content-class": "contextmenu-tooltip",
      "open-delay": "100",
      "min-width": "100px",
      "max-width": "200px"
    }
  }, [_vm.$slots.tooltip ? _vm._t("tooltip") : _setup.props.tooltip ? _c('pre', [_vm._v(_vm._s(_setup.props.tooltip))]) : _vm._e()], 2) : _vm._e(), _vm._v(" "), _vm.$slots.help || _setup.props.help ? _c('div', {
    staticClass: "px-2 text-caption secondary-text--text"
  }, [_vm.$slots.help ? _vm._t("help") : _setup.props.help ? _c('pre', [_vm._v(_vm._s(_setup.props.help))]) : _vm._e()], 2) : _vm._e(), _vm._v(" "), _setup.props.divided ? _c(VDivider, {
    staticClass: "my-2"
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };