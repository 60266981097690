/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ContextMenuItem.vue?vue&type=template&id=b070f652"
import script from "./ContextMenuItem.vue?vue&type=script&lang=ts&setup=true"
export * from "./ContextMenuItem.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ContextMenuItem.vue?vue&type=style&index=0&id=b070f652&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContextMenuItemButton: require('/code/src/components/menu/ContextMenuItemButton.vue').default,ContextMenu: require('/code/src/components/menu/ContextMenu.vue').default,FloatingTooltip: require('/code/src/components/tooltip/FloatingTooltip.vue').default})
