import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _setup$props$icon;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('button', _vm._g(_vm._b({
    staticClass: "tw-btn tw-btn-text-on-surface tw-w-full tw-h-7 !tw-rounded-none context-menu-item-button",
    attrs: {
      "type": "button",
      "disabled": _setup.props.disabled
    }
  }, 'button', _vm.$attrs, false), _vm.$listeners), [_setup.props.icon || _setup.props.iconIndex ? _c(VIcon, {
    style: _setup.props.icon ? "" : "visibility: hidden",
    attrs: {
      "size": "18",
      "color": _setup.props.iconColor
    },
    domProps: {
      "textContent": _vm._s((_setup$props$icon = _setup.props.icon) !== null && _setup$props$icon !== void 0 ? _setup$props$icon : "mdi-check")
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-ml-2 tw-text-body-2",
    domProps: {
      "textContent": _vm._s(_setup.props.label)
    }
  }), _vm._v(" "), _c(VSpacer), _vm._v(" "), _vm.$slots.default ? _vm._t("default") : _setup.props.appendIcon ? _c(VIcon, {
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s(_vm.appendIcon))]) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };